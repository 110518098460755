const defaultMessages = {
  auth: {
    auth_check_error: 'Por favor inicie sesión para continuar',
    logout: 'Cerrar Sesión',
    password: 'Contraseña',
    sign_in: 'Acceder',
    sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
    user_menu: 'Perfil',
    username: 'Usuario'
  },
  boolean: {
    false: 'No',
    null: ' ',
    true: 'Sí'
  },
  configurable: {
    customize: 'Personalizar'
  },
  input: {
    file: {
      upload_several:
        'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
      upload_single:
        'Arrastre un archivo para subir o haga clic para seleccionarlo.'
    },
    image: {
      upload_several:
        'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
      upload_single:
        'Arrastre alguna imagen para subir o haga clic para seleccionarla.'
    },
    password: {
      toggle_hidden: 'Mostrar contraseña',
      toggle_visible: 'Ocultar contraseña'
    },
    references: {
      all_missing: 'No se pueden encontrar datos de referencias.',
      many_missing:
        'Al menos una de las referencias asociadas parece no estar disponible.',
      single_missing: 'La referencia asociada no parece estar disponible.'
    }
  },
  message: {
    about: 'Acerca de',
    are_you_sure: '¿Está seguro?',
    bulk_delete_content:
      '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
    bulk_delete_title:
      'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
    bulk_update_content:
      '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
    bulk_update_title:
      'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
    delete_content: '¿Seguro que quiere eliminar este item?',
    delete_title: 'Eliminar %{name} #%{id}',
    details: 'Detalles',
    error:
      'Se produjo un error en el cliente y su solicitud no se pudo completar',
    invalid_form:
      'El formulario no es válido. Por favor verifique si hay errores',
    loading: 'La página se está cargando, espere un momento por favor',
    no: 'No',
    not_found:
      'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
    unsaved_changes:
      'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
    yes: 'Sí'
  },
  navigation: {
    next: 'Siguiente',
    no_more_results:
      'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
    no_results: 'No se han encontrado resultados',
    page_out_from_begin: 'No puede ir antes de la página 1',
    page_out_from_end: 'No puede ir después de la última página',
    page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
    page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
    page_rows_per_page: 'Filas por página:',
    prev: 'Anterior',
    skip_nav: 'Saltar al contenido'
  },
  notification: {
    bad_item: 'Elemento incorrecto',
    canceled: 'Acción cancelada',
    created: 'Elemento creado',
    data_provider_error:
      'Error del proveedor de datos. Consulte la consola para más detalles.',
    deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
    http_error: 'Error de comunicación con el servidor',
    i18n_error:
      'No se pudieron cargar las traducciones para el idioma especificado',
    item_doesnt_exist: 'El elemento no existe',
    logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
    not_authorized: 'No tiene autorización para acceder a este recurso.',
    updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados'
  },
  page: {
    create: 'Crear %{name}',
    dashboard: 'Tablero',
    edit: '%{name} #%{id}',
    empty: 'Sin %{name} todavía.',
    error: 'Algo salió mal',
    invite: '¿Quiere agregar una?',
    list: 'Lista de %{name}',
    loading: 'Cargando',
    not_found: 'No encontrado',
    show: '%{name} #%{id}'
  },
  ra: {
    action: {
      add: 'Añadir',
      add_filter: 'Añadir filtro',
      back: 'Ir atrás',
      bulk_actions:
        '1 item seleccionado |||| %{smart_count} items seleccionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      clone: 'Clonar',
      close: 'Cerrar',
      close_menu: 'Cerrar menú',
      confirm: 'Confirmar',
      create: 'Crear',
      create_item: 'Crear %{item}',
      delete: 'Eliminar',
      edit: 'Editar',
      expand: 'Expandir',
      export: 'Exportar',
      list: 'Listar',
      move_down: 'Mover abajo',
      move_up: 'Mover arriba',
      open_menu: 'Abrir menú',
      refresh: 'Refrescar',
      remove: 'Borrar',
      remove_filter: 'Borrar filtro',
      remove_all_filters: 'Borrar todos los filtros',
      save: 'Guardar',
      search: 'Buscar',
      select_all: 'Seleccionar todo',
      select_row: 'Seleccionar esta fila',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
      update: 'Actualizar'
    },
    auth: {
      auth_check_error: 'Por favor inicie sesión para continuar',
      logout: 'Cerrar Sesión',
      password: 'Contraseña',
      sign_in: 'Acceder',
      sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
      user_menu: 'Perfil',
      username: 'Usuario',
      email: 'Email'
    },
    boolean: {
      false: 'No',
      null: ' ',
      true: 'Sí'
    },
    configurable: {
      customize: 'Personalizar'
    },
    input: {
      file: {
        upload_several:
          'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
        upload_single:
          'Arrastre un archivo para subir o haga clic para seleccionarlo.'
      },
      image: {
        upload_several:
          'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
        upload_single:
          'Arrastre alguna imagen para subir o haga clic para seleccionarla.'
      },
      password: {
        toggle_hidden: 'Mostrar contraseña',
        toggle_visible: 'Ocultar contraseña'
      },
      references: {
        all_missing: 'No se pueden encontrar datos de referencias.',
        many_missing:
          'Al menos una de las referencias asociadas parece no estar disponible.',
        single_missing: 'La referencia asociada no parece estar disponible.'
      }
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Está seguro?',
      bulk_delete_content:
        '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
      bulk_delete_title:
        'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
      bulk_update_content:
        '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
      bulk_update_title:
        'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
      delete_content: '¿Seguro que quiere eliminar este item?',
      delete_title: 'Eliminar %{name} #%{id}',
      details: 'Detalles',
      error:
        'Se produjo un error en el cliente y su solicitud no se pudo completar',
      invalid_form:
        'El formulario no es válido. Por favor verifique si hay errores',
      loading: 'La página se está cargando, espere un momento por favor',
      no: 'No',
      not_found:
        'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
      unsaved_changes:
        'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
      yes: 'Sí'
    },
    navigation: {
      next: 'Siguiente',
      no_more_results:
        'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
      no_results: 'No se han encontrado resultados',
      page_out_from_begin: 'No puede ir antes de la página 1',
      page_out_from_end: 'No puede ir después de la última página',
      page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      prev: 'Anterior',
      skip_nav: 'Saltar al contenido'
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      data_provider_error:
        'Error del proveedor de datos. Consulte la consola para más detalles.',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
      http_error: 'Error de comunicación con el servidor',
      i18n_error:
        'No se pudieron cargar las traducciones para el idioma especificado',
      item_doesnt_exist: 'El elemento no existe',
      logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
      not_authorized: 'No tiene autorización para acceder a este recurso.',
      updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados'
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Tablero',
      edit: '%{name} #%{id}',
      empty: 'Sin %{name} todavía.',
      error: 'Algo salió mal',
      invite: '¿Quiere agregar una?',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}'
    },
    saved_queries: {
      new_label: 'Guardar la búsqueda actual',
      remove_label_with_name: 'Eliminar la búsqueda "%{name}"',
      new_dialog_title: 'Guardar la búsqueda actual',
      query_name: 'Nombre de la búsqueda',
      remove_dialog_title: 'Eliminar la búsqueda',
      remove_message: '¿Está seguro que quiere eliminar esta búsqueda?'
    },
    sort: {
      ASC: 'ascendente',
      DESC: 'descendente',
      sort_by: 'Ordenar por %{field} %{order}'
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      maxLength: 'Debe contener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe contener %{min} caracteres al menos',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de: %{options}',
      passwordMismatched: 'Las contraseñas no coinciden',
      regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
      required: 'Requerido'
    }
  },
  sort: {
    ASC: 'ascendente',
    DESC: 'descendente',
    sort_by: 'Ordenar por %{field} %{order}'
  },
  validation: {
    email: 'Debe ser un correo electrónico válido',
    maxLength: 'Debe contener %{max} caracteres o menos',
    maxValue: 'Debe ser %{max} o menos',
    minLength: 'Debe contener %{min} caracteres al menos',
    minValue: 'Debe ser al menos %{min}',
    number: 'Debe ser un número',
    oneOf: 'Debe ser uno de: %{options}',
    passwordMismatched: 'Las contraseñas no coinciden',
    regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
    required: 'Requerido',
    isGreater: 'Debe ser menor',
    moreThanZero: 'Debe ser mayor que 0',
    positiveNumber: 'Debe ser un número positivo',
    dateTodayOrGreater: 'Debe ser una fecha igual o posterior a hoy',
    nifNotValid: 'El DNI no es válido',
    dateOneYearOrLess: 'Debe ser una fecha menor de un año',
    notEmailType: 'No tiene formato de email',
    maxThreeDecimals: 'Máximo 3 decimales'
  }
};

const esMessages = {
  ...defaultMessages,
  errors: {
    email_exist: 'El correo ya existe',
    invalid_file: 'Fichero invalido',
    login: 'Email o contraseña erronea',
    not_privilege_exception: 'No tienes privilegios suficientes',
    password_mismatch: 'Discordancia en la contraseña',
    recover_password_not_found:
      'La contraseña de recuperación no se ha encontrado',
    unrecognized_error:
      'Ha ocurrido un error porfavor contacte con el adminsitrador de la web',
    user_not_found: 'Usuario no encontrado',
    extension_not_valid: 'Extension no valida',
    diameter_flow_not_in_excel:
      'El caudal de los diámetros no existe en el Excel.',
    diameter_already_exist: 'El díametro ya existe',
    length_not_valid: 'La logitud tiene que ser entre 6 y 16 caracteres.'
  },
  shared: {
    form: {
      toolbar: {
        actions: {
          accept: 'Aceptar',
          cancel: 'Cancelar',
          saveChanges: 'Guardar cambios',
          save: 'Guardar'
        }
      }
    },
    list: {
      empty: {
        title: 'No hay datos disponibles'
      }
    },
    navigation: {
      prev: 'Volver'
    }
  },
  resources: {
    breadcrumbs: {},
    auth: {
      generate_password: {
        form: {
          confirmPassword: 'Repetir Contraseña',
          password: 'Contraseña'
        },
        subtitle: 'Generar contraseña',
        title: 'SAFEM'
      },
      recovery_password: {
        register: ' Registrar Usuario',
        button: 'Enviar',
        link: 'aqui',
        message:
          'Por favor, indica tu email aquí abajo para recibir instrucciones sobre la forma de recuperar tu contraseña. Si has accedido a esta sección sin querer, puedes volver al inicio clicando ',
        title: 'SAFEM'
      },
      remind_user: 'Mantener sesión',
      reset_password: {
        form: {
          confirmPassword: 'Repetir Contraseña',
          password: 'Contraseña'
        },
        subtitle: 'Actualizar contraseña',
        title: 'SAFEM'
      },
      recovery_password_button: '¿Has olvidado tu contraseña?'
    },
    'efficiency-tests': {
      name: 'Test |||| Tests',
      tabs: {
        data: 'Datos de la prueba',
        results: 'Resultados de la prueba',
        curves: 'Curvas'
      },
      fields: {
        selection: 'Seleccionar',
        pumpModel: 'Modelo de bomba',
        client: 'Cliente',
        identifier: 'ID',
        orderNumber: 'Número de pedido',
        date: 'Fecha',
        testNumber: 'Nº Prueba',
        pumpId: 'ID de bomba',
        testStatus: 'Estado prueba',
        testFlow: 'CAUDAL PRUEBA',
        emptyMmhg: 'VACIO: mmHG',
        openHoles: 'AGUJEROS ABIERTOS',
        consume: 'CONSUMO',
        curveKw: 'KW TEST',
        aspirationTemperature: 'Temperatura de aspiración',
        voltage: 'Voltaje',
        observations: 'Observaciones',
        holesOpen: 'Agujeros abiertos',
        waterTemp: 'TªAgua(ºC)',
        pa: 'Pa mBar',
        airTemp: 'TªAire(ºC)',
        pumpRpm: 'RPM BOMBA',
        waterFlow: 'Caudal de agua (LPM)',
        flowPercentaje: '% del Caudal',
        flowComparation: 'Comparativas de caudales',
        consumeComparation: 'Comparativas de consumo específico [kw/m3/min]',
        downloadDataPdf: 'Imprimir datos',
        downloadResultsPdf: 'Imprimir resultados',
        downloadMergedPdf: 'Imprimir todo',
        curve: 'Curva',
        measurement: 'Medición',
        difference: 'Diferencia',
        blindSide: 'LC',
        engineSide: 'LT',
        absorbedPower: 'Potencia absorbida (kW)',
        flow: 'Caudal (m3/h)',
        suctionPressure: 'Presión de aspiración (mbar)',
        satref: 'SAT/REF.',
        ambientPressure: 'Pres-amb.',
        ringQ: 'Q anillo (LPM)',
        motorKw: 'Motor kW',
        tension: 'Tensión',
        aspirationTemp: 'Temp. asp. ºC',
        motorRpm: 'RPM motor',
        effFactor: 'Eff factor',
        holeDiameter: 'Diam. Orif.',
        create: 'Crear',
        model: 'Modelo',
        testDetail: 'Detalle de prueba',
        addRow: 'Añadir fila',
        addCells: 'Añadir diámetros agujeros',
        diameter: 'Diámetro',
        currentType: 'Tipo de corriente',
        m3min: 'm3/min',
        mm: 'mm',
        lt: 'LT',
        lc: 'LC',
        a: 'A',
        cos: 'COS Φ'
      },
      table: {
        addRow: 'Añadir fila'
      },
      filters: {
        search: 'Buscar'
      },
      delete: {
        title: 'Eliminar test',
        message: '¿Estás seguro de que quieres eliminar el test?'
      },
      notifications: {
        create: {
          success: 'Test creado correctamente.'
        },
        delete: {
          success: 'Test eliminado correctamente.'
        },
        save: {
          success: 'Test guardado correctamente.',
          error:
            'Ha habido un problema al guardar el test. Verifica que todos los campos sean correctos.'
        }
      },
      customers: {
        create: {
          title: 'Desea crear el cliente'
        }
      }
    },
    diameters: {
      name: 'Diámetro |||| Diámetros',
      fields: {
        diameter: 'Diámetro',
        flowsList: 'Listado de caudales'
      },
      filters: {
        name: 'Buscar por nombre',
        search: 'Buscar'
      },
      delete: {
        title: 'Eliminar diámetro',
        message: '¿Estás seguro de que quieres eliminar el diámetro?'
      },
      notifications: {
        create: {
          success: 'Diámetro creado correctamente.'
        },
        delete: {
          success: 'Diámetro eliminado correctamente.'
        }
      },
      flows: {
        fields: {
          file: 'Archivo',
          flow: 'Caudal',
          flowLines: 'Lineas de caudal',
          import: 'Importar',
          create: 'Crear caudal',
          flowDetail: 'Detalle de caudal'
        },
        delete: {
          title: 'Eliminar caudal',
          message: '¿Estás seguro de que quieres eliminar el caudal?'
        },
        notifications: {
          create: {
            success: 'Caudal creado correctamente.'
          },
          delete: {
            success: 'Caudal eliminado correctamente.'
          }
        }
      }
    },
    pumps: {
      name: 'Bomba |||| Bombas',
      fields: {
        model: 'Modelo',
        manufacturer: 'Fabricante',
        pumpName: 'Nombre de bomba',
        speedsList: 'Listado de velocidades'
      },
      filters: {
        name: 'Buscar por nombre',
        search: 'Buscar'
      },
      delete: {
        title: 'Eliminar bomba',
        message: '¿Estás seguro de que quieres eliminar la bomba?'
      },
      notifications: {
        create: {
          success: 'Bomba creada correctamente.'
        },
        delete: {
          success: 'Bomba eliminada correctamente.'
        }
      },
      speeds: {
        fields: {
          file: 'Archivo',
          speed: 'Velocidad',
          pumpQuantity: 'Pump Quantity',
          capacityReqd: 'Capacity Reqd (CMM)',
          capacityActual: 'CAPACITY ACTUAL (CMM)',
          vacuum: 'VACUUM (KPA V)',
          vaporTemp: 'Vapor Temp (DEGC)',
          sealWaterTemp: 'Seal Water Temp (DEGC)',
          efficiency: 'Efficiency (KM/CMM)',
          sealFlow: 'Seal Flow (LPM)',
          exitTemp: 'Exit Temp. (DEGC)',
          pumpSpeed: 'Pump Speed',
          powerAbsorved: 'POWER ABSORVED (KW)',
          motorKw: 'MOTOR (KW)',
          motorRpm: 'MOTOR RPM',
          dryAir: 'STD Dry Air (CMM)',
          exitAir: 'Exit Air (NCMM)',
          pumpLines: 'Líneas de bombas',
          import: 'Importar',
          create: 'Crear velocidad',
          speedDetail: 'Detalle de velocidad'
        },
        filters: {
          name: 'Buscar por nombre'
        },
        delete: {
          title: 'Eliminar velocidad',
          message: '¿Estás seguro de que quieres eliminar la velocidad?'
        },
        notifications: {
          create: {
            success: 'Velocidad creada correctamente.'
          },
          delete: {
            success: 'Velocidad eliminada correctamente.'
          }
        }
      }
    },
    users: {
      name: 'Usuario |||| Usuarios',
      fields: {
        date: 'Fecha',
        email: 'Email',
        firstName: 'Nombre',
        lastName: 'Apellido',
        password: 'Contraseña',
        secondLastName: 'Segundo Apellido',
        phone: 'Teléfono',
        view: 'Ver',
        manage: 'Gestionar',
        privilege: {
          test: 'Tests',
          pump: 'Bombas',
          diameter: 'Diámetros',
          user: 'Usuarios'
        },
        permissions: 'Permisos',
        editUser: 'Editar usuario'
      },
      filters: {
        name: 'Buscar por nombre',
        search: 'Buscar'
      },
      delete: {
        title: 'Eliminar usuario',
        message: '¿Estás seguro de que quieres eliminar el usuario?'
      },
      notifications: {
        create: {
          success: 'Usuario creado correctamente.'
        },
        update: {
          success: 'Usuario editado correctamente.'
        },
        delete: {
          success: 'Usuario eliminado correctamente.'
        },
        passwordRecovery: {
          success:
            'El email para recordar contraseña se ha enviado correctamente.'
        }
      }
    }
  }
};

export default esMessages;
